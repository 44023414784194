import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from '../hoc/PrivateRoute';
import NotFound from '../NotFound';
import ServerError500 from '../ServerError500';
import Maintenance from '../MaintenancePage';
import { AuthContext } from '../contexts/AuthContext';
import UploadImportDevice from '../UploadImportDevice';
import TalosEditPage from '../Talos/TalosEditPage';
import DocumentListPage from '../DocumentsList/DocumentListPage';

const Networking = lazy(() => import('../Networking'));
const OrgHome = lazy(() => import('../MyOrganization/OrgHome'));
const Device = lazy(() => import('../Device/Device'));
const Dashboard = lazy(() => import('../Dashboard'));
const Devices = lazy(() => import('../Devices'));
const Network = lazy(() => import('../NetworkManagement/Network'));
const Templates = lazy(() => import('../Templates'));
const Organization = lazy(() => import('../Organizations/Organization'));
const OrganizationsList = lazy(() => import('../Organizations'));
const VdcUsage = lazy(() => import('../VDCUsage'));
const UsageReports = lazy(() => import('../UsageReports'));
const SingleUsageReport = lazy(() => import('../UsageReports/SingleUsageReport'));
const ResourceUsageReports = lazy(() => import('../ResourceUsageReports'));
const BillingPlan = lazy(() => import('../BillingPlan'));
const BillingViewEdit = lazy(() => import('../ManageBillings/BillingViewEdit'));
const ManageBillings = lazy(() => import('../ManageBillings'));
const BillingDetails = lazy(() => import('../BillingDetails'));
const OperationalArea = lazy(() => import('../OperationalArea'));
const UserAccount = lazy(() => import('../UserAccount'));
const SalesDashboard = lazy(() => import('../SalesDashboard'));
const KubernetesPage = lazy(() => import('../Cluster/ClusterListPage'));
const AddClusterPage = lazy(() => import('../Cluster/ClusterPageCreationPage'));
const TalosPage = lazy(() => import('../Talos/TalosListPage'));
const AddTalosPage = lazy(() => import('../Talos/TalosCreationPage'));
const DBListPage = lazy(() => import('../Databases/DatabaseListPage'));
const DBCreationPage = lazy(() => import('../Databases/DatabaseCreationPage'));
const DeviceCreationSuccessPage = lazy(() => import('../AddDevicePage/DeviceCreationSuccess'));
const ClusterPage = lazy(() => import('../Cluster/ClusterPage/ClusterPage'));
const AddOrganizationPage = lazy(() => import('../AddOrganizationPage/AddOrganizationPage'));
const LBListPage = lazy(() => import('../LoadBalancer/LoadBalancerListPage'));
const LBCreationPage = lazy(() => import('../LoadBalancer/LoadBalancerCreation'));
const LBPage = lazy(() => import('../LoadBalancer/LoadBalancerPage'));

const WAFListPage = lazy(() => import('../WAF/WAFListPage'));
const WAFCreationPage = lazy(() => import('../WAF/WAFCreation'));
const WAFPage = lazy(() => import('../WAF/WAFPage'));

const DRAASListPage = lazy(() => import('../DisasterRecovery/DRAASListPage'));

const DNSPage = lazy(() => import('../DNS/DNSPage'));
// const DNSListPage = lazy(() => import('../DNS/DNSListPage'));
const FwListPage = lazy(() => import('../Firewall/FirewallListPage'));
const FwCreationPage = lazy(() => import('../Firewall/FirewallCreation'));
const DNSCreationPage = lazy(() => import('../DNS/DNSCreationPage'));
const FwPage = lazy(() => import('../Firewall/FirewallPage'));
const AccountingPage = lazy(() => import('../Accounting'));
const Announcement = lazy(() => import('../AdminPanel/Announcements/AnnouncementsList'));
const AnnouncementPage = lazy(() => import('../AdminPanel/Announcements/AnnouncementPage'));

const PSListPage = lazy(() => import('../PersistentStorage/PersistentStorageListPage/PersistentStorageListPage'));
const PSCreationPage = lazy(() =>
  import('../PersistentStorage/PersistentStorageCreationPage/PersistentStorageCreationPage')
);
const PSPage = lazy(() => import('../PersistentStorage/PersistentStoragePage/PersistentStoragePage'));

const SidebarRoutes = () => {
  const auth = useContext(AuthContext);

  // check whether whitelabeling is not turned on
  // if turned on we don't show several paths
  const baseLabeling = process.env.REACT_APP_WHITELABELING !== 'true';
  let showBillingDetails = true;
  if (
    !auth.userPermissions.allowManagePaymentMethods &&
    !auth.userPermissions.allowViewOrganizationInvoices &&
    !auth.userPermissions.allowViewCreditsInfo &&
    !auth.userPermissions.allowManageBillingPlans
  ) {
    showBillingDetails = false;
  }

  const homeRoute = <PrivateRoute key={'Home' + auth.tenant} path="/" exact component={Dashboard} />;
  const OrgHomeRoute = <PrivateRoute key="OrgHome" path="/my-organization" component={OrgHome} />;
  const LBCreationRoute = (
    <PrivateRoute
      key="Load Balancer Creation"
      path="/networking/load-balancer/add-load-balancer"
      component={LBCreationPage}
    />
  );
  const FwCreationRoute = (
    <PrivateRoute key="Firewall Creation" path="/networking/firewall/add-firewall" component={FwCreationPage} />
  );
  const DNSCreationRoute = (
    <PrivateRoute key="DNS Creation" exact path="/networking/dns/add-dns" component={DNSCreationPage} />
  );
  const DNSRoute = <PrivateRoute key="DNS" exact path="/networking/dns/:tenantId/:domain" component={DNSPage} />;
  const DeviceCreationSuccessRoute = (
    <PrivateRoute key="DeviceCreationSuccess" path="/device-success" component={DeviceCreationSuccessPage} />
  );
  const DeviceRoute = <PrivateRoute key="Device" path="/all-devices/:id/:tenantId" component={Device} />;
  const DashboardRoute = <PrivateRoute key="Dashboard" path="/dashboard" component={Dashboard} />;
  const DevicesRoute = <PrivateRoute key="Devices" path="/all-devices" component={Devices} />;
  const NetworkRoute = (
    <PrivateRoute
      key="Network"
      path="/networking/:tenantId/:networkId"
      component={Network}
      match={{
        regex: /^(?!web-application-firewall|dns|load-balancer|firewall).+/,
        valTOMatch: 'tenantId',
      }}
    />
  );
  const KubernetesRoute = <PrivateRoute key="Kubernetes" path="/kubernetes" exact component={KubernetesPage} />;
  const AddClusterRoute = (
    <PrivateRoute key="AddClusterRoute" path="/kubernetes/add-new-cluster" component={AddClusterPage} />
  );
  const TalosRoute = <PrivateRoute key="Talos" path="/kubernetes-talos" exact component={TalosPage} />;
  const AddTalosRoute = (
    <PrivateRoute key="AddTalosRoute" path="/kubernetes-talos/add-new-cluster" component={AddTalosPage} />
  );
  const TalosEditRoute = (
    <PrivateRoute key="TalosEditRoute" path="/kubernetes-talos/:id/:talosId" component={TalosEditPage} />
  );
  const DBListRoute = <PrivateRoute key="DBListPage" path="/databases" exact component={DBListPage} />;
  const DBCreationRoute = (
    <PrivateRoute key="DBCreationRoute" path="/databases/add-new-database" component={DBCreationPage} />
  );
  const KubernetesClusterRoute = (
    <PrivateRoute key="KubernetesCluster" path="/kubernetes/:id/:kubernetesId" component={ClusterPage} />
  );
  const CloudInitKubernetesClusterRoute = (
    <PrivateRoute key="KubernetesCluster" path="/kubernetes-ci/:id/:kubernetesId" component={ClusterPage} />
  );
  const TemplatesRoute = <PrivateRoute key="Templates" path="/templates-and-isos" component={Templates} />;

  const VdcUsageRoute = <PrivateRoute key="VdcUsage" path="/usage-overview" component={VdcUsage} />;

  const RevenueIntelligenceRoute = (
    <PrivateRoute key="RevenueIntelligence" path="/revenue-intelligence" component={UsageReports} />
  );

  const VdcUsagesReportRoute = <PrivateRoute key="UsageReports" path="/usage-reports" component={UsageReports} />;

  const VdcSingleUsageRoute = (
    <PrivateRoute key="UsageSingleReport" path="/usage-reports/:reportId" component={SingleUsageReport} />
  );

  const ResourceUsageReportsRoute = (
    <PrivateRoute key="ResourceUsageReports" path="/resource-usage-reports" component={ResourceUsageReports} />
  );
  const BillingPlanRoute = <PrivateRoute key="BillingPlan" path="/billing-plan" component={BillingPlan} />;
  const UserAccountRoute = <PrivateRoute key="UserAccount" path="/user-account" component={UserAccount} />;
  const OperationalAreaRoute = (
    <PrivateRoute key="OperationalArea" path="/operational-area" component={OperationalArea} />
  );
  const SalesDashboardRoute = <PrivateRoute key="SalesDashboard" path="/sales-dashboard" component={SalesDashboard} />;
  const NetworkingRoute = <PrivateRoute key="Networking" path="/networking" component={Networking} />;
  const OrganizationsListRoute = (
    <PrivateRoute key="OrganizationsList" path="/all-organizations" exact component={OrganizationsList} />
  );
  const AddOrganizationRoute = (
    <PrivateRoute
      key="AddOrganizationPage"
      path="/all-organizations/add-new-organization"
      component={AddOrganizationPage}
    />
  );
  const OrganizationRoute = (
    <PrivateRoute key="Organization" path={`/all-organizations/organization/:id`} component={Organization} />
  );
  const BillingViewEditAddRoute = (
    <PrivateRoute
      key="BillingViewEditAdd"
      path="/billing-details/manage-billing-plans/add-new/:type"
      component={BillingViewEdit}
    />
  );
  const BillingViewEditRoute = (
    <PrivateRoute key="BillingViewEdit" path="/billing-details/manage-billing-plans/:id" component={BillingViewEdit} />
  );
  const ManageBillingsRoute = (
    <PrivateRoute key="ManageBillings" path="/manage-billing-plans" exact component={ManageBillings} />
  );
  const BillingDetailsRoute = <PrivateRoute key="BillingDetails" path="/billing-details" component={BillingDetails} />;
  const LBListRoute = <PrivateRoute key="Load Balancers" path="/load-balancer" exact component={LBListPage} />;
  const LBRoute = (
    <PrivateRoute key="Load Balancer" path="/networking/load-balancer/:tenantId/:id" component={LBPage} />
  );
  const WAFListRoute = (
    <PrivateRoute key="Web Application Firewalls" path="/web-application-firewall" exact component={WAFListPage} />
  );
  const WAFCreationRoute = (
    <PrivateRoute
      key="Web Application Firewall Creation"
      path="/networking/web-application-firewall/add-web-application-firewall"
      component={WAFCreationPage}
      exact
    />
  );
  const WAFPageRoute = (
    <PrivateRoute
      key="Web Application Firewall"
      path="/networking/web-application-firewall/:tenantId/:id"
      component={WAFPage}
    />
  );
  const FwListRoute = <PrivateRoute key="Firewalls" path="/firewall" exact component={FwListPage} />;
  const FwRoute = <PrivateRoute key="Firewall" path="/networking/firewall/:tenantId/:id" component={FwPage} />;
  const PSCreationRoute = (
    <PrivateRoute
      key="Persistent Storage Creation"
      path="/persistent-storage/add-persistent-storage"
      component={PSCreationPage}
    />
  );
  const PSListRoute = (
    <PrivateRoute key="Persistent Storages" path="/persistent-storage" exact component={PSListPage} />
  );
  const PSRoute = <PrivateRoute key="Persistent Storage" path="/persistent-storage/:id" component={PSPage} />;
  const AccountingRoute = <PrivateRoute key="Accounting" path="/accounting" component={AccountingPage} />;
  const AnnouncementPageRoute = (
    <PrivateRoute key="Announcement Page" path="/announcements/:identifier" component={AnnouncementPage} />
  );
  const AnnouncementRoute = <PrivateRoute key="Announcements" path="/announcements" component={Announcement} />;

  const DocumentsRoute = <PrivateRoute key="Documents" path="/documents" component={DocumentListPage} />;

  const DRAASListRoute = (
    <PrivateRoute key="Disaster Recovery" path="/disaster-recovery" exact component={DRAASListPage} />
  );

  const NotFoundPage = <PrivateRoute key="Not Found Page" path="/not-found" component={NotFound} />;

  const ServerError500Page = (
    <PrivateRoute key="Server Error 500" path="/server-error-500" component={ServerError500} />
  );

  const MaintenancePage = <Route key="On Maintenance" path="/maintenance" component={Maintenance} />;

  const UploadImportDevicePage = (
    <Route key="Upload and Import Device" path="/upload-import-device" component={UploadImportDevice} />
  );

  //default routes are visible for all users (necessary to take permissions into consideration)
  const defaultRoutes = [
    homeRoute,
    auth.userPermissions.allowViewOrganizations && OrgHomeRoute,
    auth.userPermissions.allowViewVirtualMachines && DeviceRoute,
    auth.userPermissions.allowViewVirtualMachines && DevicesRoute,
    DashboardRoute,
    baseLabeling && KubernetesRoute,
    baseLabeling && DBListRoute,
    baseLabeling && DBCreationRoute,
    baseLabeling && AddClusterRoute,
    baseLabeling && auth.userPermissions.allowManageKubernetes && TalosRoute,
    baseLabeling && auth.userPermissions.allowManageKubernetes && AddTalosRoute,
    baseLabeling && auth.userPermissions.allowManageKubernetes && TalosEditRoute,
    baseLabeling && KubernetesClusterRoute,
    baseLabeling && CloudInitKubernetesClusterRoute,
    auth.userPermissions.allowViewLoadBalancers && LBListRoute,
    auth.userPermissions.allowCreateLoadBalancer && LBCreationRoute,
    auth.userPermissions.allowViewLoadBalancer && LBRoute,
    auth.userPermissions.allowViewWebApplicationFirewalls && WAFListRoute,
    auth.userPermissions.allowCreateWebApplicationFirewall && WAFCreationRoute,
    auth.userPermissions.allowViewWebApplicationFirewall && WAFPageRoute,
    auth.userPermissions.allowViewTemplates && TemplatesRoute,
    auth.userPermissions.allowViewUsageOverview && VdcSingleUsageRoute,
    auth.userPermissions.allowViewUsageOverview && VdcUsageRoute,
    auth.userPermissions.allowViewUsageOverview && VdcUsagesReportRoute,
    auth.userPermissions.allowViewUsageOverview && RevenueIntelligenceRoute,
    ResourceUsageReportsRoute,
    BillingPlanRoute,
    UserAccountRoute,
    baseLabeling && auth.userPermissions.allowViewNetworking && DNSCreationRoute,
    baseLabeling && auth.userPermissions.allowViewNetworking && DNSRoute,
    auth.userPermissions.allowViewFirewalls && FwListRoute,
    auth.userPermissions.allowCreateFirewall && FwCreationRoute,
    auth.userPermissions.allowViewFirewall && FwRoute,
    baseLabeling && PSCreationRoute,
    baseLabeling && PSListRoute,
    baseLabeling && PSRoute,
    NetworkRoute,
    NetworkingRoute,
    auth.userPermissions.allowManageInvoices && AccountingRoute,
    AnnouncementPageRoute,
    AnnouncementRoute,
    DeviceCreationSuccessRoute,
    auth.userPermissions.allowManageBillingPlans && BillingViewEditAddRoute,
    auth.userPermissions.allowManageBillingPlans && BillingViewEditRoute,
    showBillingDetails && BillingDetailsRoute,
    auth.userPermissions.allowManageOperations && OperationalAreaRoute,
    auth.userPermissions.allowManageSalesDashboard && SalesDashboardRoute,
    DRAASListRoute,
    NotFoundPage,
    ServerError500Page,
    MaintenancePage,
    UploadImportDevicePage,
    DocumentsRoute,
  ].filter(el => el);
  const [routes, setRoutes] = useState(defaultRoutes);

  useEffect(() => {
    const additionalRoutes = [...defaultRoutes];

    //type 1 for Reseller
    if (auth.tenantType === 1) {
      const resellerRoutes = [
        OrganizationRoute,
        auth.userPermissions.allowViewOrganizations && OrganizationsListRoute,
        AddOrganizationRoute,
        auth.userPermissions.allowManageBillingPlans && ManageBillingsRoute,
      ].filter(el => el);
      additionalRoutes.push([...resellerRoutes]);
    }
    setRoutes(additionalRoutes);
  }, [auth.tenantType, auth.user.id, auth.roles]);

  return (
    <Suspense fallback={null}>
      <Switch key={auth && auth.user && auth.user.id}>
        {routes}
        <PrivateRoute key="NotFound" component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default SidebarRoutes;
